import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router';
import { Context } from '../store/store'


export default function(){
    const {state, actions} = useContext(Context);
    const history = useHistory();
    
    useEffect(function(){
        if(state.user.username==null && state.user.password==null){
            history.replace("/");
        }
    }, [state.user.username, state.user.password]);


    useEffect(function(){
        actions.favourites.reset();
    }, [state.user.username]);


    useEffect(function(){
        actions.user.logout();
    }, []);

    return <div></div>

}