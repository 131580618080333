
export default function(){
    return (
        <div className="Views">
            <iframe
                src="https://vistastreet.s3.ap-southeast-2.amazonaws.com/Exported+Tour/index.htm"
                name="TOUR NAME"
                title="TOUR NAME"
                width="100%"
                height="100%"
                frameBorder="0"
                allowFullScreen={true}
                allow="fullscreen; accelerometer; gyroscope; magnetometer; vr; xr; xr-spatial-tracking; autoplay; camera; microphone"
            ></iframe>
        </div>
    )
}