import { useEffect, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

type Props = {
    children:React.ReactNode,
    showButtons:Boolean
}


export default function({children, showButtons}:Props){
    return (
        <div className="Zoom">
            <TransformWrapper panning={{
                velocityDisabled: true
            }}>
                {({ zoomIn, zoomOut, resetTransform }:any) => (
                    <div>
                        <TransformComponent>
                            { children }
                        </TransformComponent>
                        { showButtons &&
                        <div className="ZoomUI">
                            <div onClick={()=>{zoomIn()}} className="ZoomIn"><img src={require('../images/interface/icon--zoom-in.png').default} alt="Zoom in" /></div>
                            <div onClick={()=>{zoomOut()}} className="ZoomOut"><img src={require('../images/interface/icon--zoom-out.png').default} alt="Zoom out" /></div>
                        </div>
                        }
                    </div>
                )}
            </TransformWrapper>
        </div>
    )
}