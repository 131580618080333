import useRaf from "@rooks/use-raf";
import { CSSProperties, MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import ImageLoader from "./ImageLoader";
import { SalesData } from "../data/sales-data";
import SaleIndicator from "./SaleIndicator";
import FloorPlanKey from "./FloorplanKey";
import { useGesture } from 'react-use-gesture'
import PlanData from "../data/plan-data";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import useOutsideClick from "@rooks/use-outside-click";
import { Context } from "../store/store";
import planData from "../data/plan-data";


type Props = {
    numLevels:number,
    numFrames:number,
    targetLevel:number,
    targetFrame:number,
    onNext: () => void,
    onPrev: () => void,
    coords: Record<number, Record<string, CoordData>>,
    images: Array<Array<HTMLImageElement>>,
    salesData: SalesData,
    onSelectionApartment: (apartmentId:string) => void,
}


type CoordData = {
    title:string,
    x:number,
    y:number,
    level:number,
    caption:string|null,

}

function modWrap(a:number, b:number){
    return a - b * Math.floor(a/b);
}



export default function({onSelectionApartment, salesData, images, numLevels, numFrames, targetLevel, targetFrame, onNext, onPrev, coords}:Props){
    const bindGesture = useGesture({
        onDragEnd: state => {
            
            if(state.movement[0] > 5){
                onPrev();
            } 
            
            if(state.movement[0]< -5){
                onNext();
            }
        }
    })
    
    return (
        <div className="FloorPlan" {...bindGesture()}>
            <Canvas onSelectionApartment={onSelectionApartment} salesData={salesData} coords={coords} numFrames={numFrames} images={images} targetLevel={targetLevel} targetFrame={targetFrame}/>
            <div className="FloorPlanRotateLeft" onClick={onPrev}><ImageLoader src={require('../images/interface/btn-rotate-left.png').default}/></div>
            <div className="FloorPlanRotateRight" onClick={onNext}><ImageLoader src={require('../images/interface/btn-rotate-right.png').default}/></div>
            <FloorPlanKey />
        </div>
    )
}


function Canvas({onSelectionApartment, salesData, coords, images, targetLevel, targetFrame, numFrames}:{onSelectionApartment:(apartmentId:string)=>void, salesData:Props['salesData'], coords:Props['coords'], numFrames:number, images:Array<Array<HTMLImageElement>>, targetLevel:number, targetFrame:number}){
    const ref = useRef<HTMLCanvasElement>(null);
    const width = 1470;
    const height = 980;

    const app = useContext(Context);

    const [state, setState] = useState({
        frame:targetFrame,
        level:targetLevel,
        lapsedFrames: 0,
        selected: null as null|string,
    });

    useEffect(function(){
        setState({
            ...state,
            selected: null,
        })
    }, [app.state.plan.apartmentId, app.state.render.src, state.level, state.frame])


    useRaf(() => {
        if(!ref.current) return;
        const c = ref.current.getContext('2d')!;
        const frame = modWrap(state.frame, numFrames+1);

        const image = images[Math.floor(state.level)][frame]
        
        c.save();
        c.scale(1470/image.width, 980/image.height);

        c.drawImage(image, 0, 0);
        c.restore();
        if(targetFrame > state.frame){
            setState({
                ...state,
                frame: (state.lapsedFrames%3!=0) ? state.frame : (state.frame+1),
                lapsedFrames: state.lapsedFrames+1,
            })
        }else if(targetFrame < state.frame){
            setState({
                ...state,
                frame: (state.lapsedFrames%3!=0) ? state.frame : (state.frame-1),
                lapsedFrames: state.lapsedFrames+1,
            })
        }

        if(state.level != targetLevel){
            setState({
                ...state,
                level: targetLevel,
            })
        }
    
    }, true);


    const coordFrame = modWrap(state.frame, numFrames+1)

    function getLevel(title:string){
        return title.length == 3
            ? parseInt(title[0])
            : parseInt(title[0]+title[1]);
    }

    function getRoom(title:string){
        return parseInt(title.slice(title.length-2));
    }

    function isSold(title:string){
        return salesData[title] && salesData[title].status == 'Sold';
    }

    function isRes(title:string){
        /*
        if(salesData[title] && salesData[title].status == '') return true;
        if(salesData[title] && salesData[title].status == 'Reserved') return true;
        if(salesData[title] && salesData[title].saleStatus == 'Reserved') return true;
        if(salesData[title] && salesData[title].saleStatus == 'Held by Agent') return true;
        if(salesData[title] && salesData[title].saleStatus == 'Held by Vendor') return true;
        
        return false;
        */
       
        if(salesData[title] && (salesData[title].status == 'Reserved' || salesData[title].status == 'In Preparation')) return true;
        return false;
        //if(salesData[title].status == 'Available' && salesData[title].saleStatus == 'Stock') return false;

        //return !isSold(title);

        /*
       if(!isSold(title)){
           if(salesData[title] && salesData[title].status != 'Available') return true;
       }
       return false;
       */
    }

    function onApartmentTouched(apartmentId:string){
        //transform: scale(1.3, 1.3) translate(0px, -0.5px);
        //onSelectionApartment(coord.title)
        setState({
            ...state,
            selected: apartmentId,
        })
    }


    function removeThumbnail(){
        // console.log("Use thumbnail");
        setState({
            ...state,
            selected: null,
        })
    }

    function onThumbClicked(selected:string, caption?:string){
        if(parseInt(selected!)){
            app.actions.plan.showApartmentPlan(selected);
        }else{
            app.actions.render.showRender(selected, caption!);
        }
    }

    
    return (
        <>
            { coords[coordFrame] && state.level==targetLevel &&
                <div className="FloorPlanCoords">{
                    Object.keys(coords[coordFrame]).map(key => {
                        var coord = coords[coordFrame][key]
                        if(targetLevel!=coord.level) return null;
                        if( !isNaN( parseFloat(coord.title) ) && !salesData[coord.title] ) return null;
                        //if(!isShown(coord.title)) return false;
                       
                        return (
                            <div key={coord.title} className="FloorPlanCoord" style={getCoordStyle(coord)} data-no-clicks={parseFloat(coord.title) && !planData.hasPlan(coord.title)} onClick={e => onApartmentTouched(coord.title)}>
                                <div className="FloorPlanIndicator">
                                    <SaleIndicator
                                        favourited={app.state.favourites.all.indexOf(coord.title)!=-1}
                                        title={coord.title}
                                        sold={isSold(coord.title)}
                                        render={!parseFloat(coord.title)}
                                        res={isRes(coord.title)}
                                    />
                                </div>
                            </div>
                        )
                    })
                }{
                    /*
                    Object.keys(coords[coordFrame]).map(key => {
                        var coord = coords[coordFrame][key]
                        if(targetLevel+1 != getLevel(coord.title)) return null;
                        if(!salesData[coord.title]) return null;
                        return (
                            <div key={coord.title} className="FloorPlanCoord" style={getCoordStyle(coord)} onClick={e => onSelectionApartment(coord.title)}>
                                <img className="FloorPlanThumb" src={`/thumbs/${PlanData.getPlan(coord.title)}.jpg`} />
                            </div>
                        )
                    })
                    */
                }</div>
            }
            <canvas width={`${width}px`} height={`${height}px`} ref={ref}/>
            { state.selected && coords[coordFrame] && coords[coordFrame][state.selected!] && 
                <FloorplanThumb onThumbClicked={() => onThumbClicked(state.selected!, coords[coordFrame][state.selected!].caption!)} onThumbClosed={removeThumbnail} coord={coords[coordFrame][state.selected]}>
                   { !!parseInt(state.selected) 
                   ? <img className="FavouritesThumbImage" src={`${process.env.PUBLIC_URL}/thumbs/${PlanData.getPlan(state.selected)}.jpg`} />
                   : <img className="FavouritesThumbImage" src={`${process.env.PUBLIC_URL}/renders-thumbs/${state.selected}.jpg`} />
                   }
                </FloorplanThumb>
            }
        </>
    )
}



function getCoordStyle(coord:{x:number, y:number}):CSSProperties{
    var x = (coord.x * 0.9875) + 0;
    var y = (coord.y - 100) + 2;
    return {
        left: x+'px',
        top: y+'px',
    }
}



function FloorplanThumb({coord, onThumbClosed, onThumbClicked, children}:{children:React.ReactNode, onThumbClicked:()=>void, onThumbClosed:()=>void, coord:{x:number, y:number}}){
    const ref = useRef<HTMLDivElement>(null!);
    const flipY = coord.y > 700;

    useOutsideClick(ref, function(){
        onThumbClosed();
    })

    const Strip = () => (
        <div className="FloorplanThumbStrip">
            <div className="FloorplanThumbIcon">
                { !flipY 
                ? <FontAwesomeIcon size="2x" icon={faChevronUp}/>
                : <FontAwesomeIcon size="2x" icon={faChevronDown}/>
                }
            </div>
        </div>
    )

    return (
        <div className={`FloorplanThumb ${flipY&&"flip-y"}`} onClick={()=>onThumbClicked()} ref={ref} style={getCoordStyle(coord)}>
            { !flipY && <Strip/>}
            {children}
            { flipY && <Strip/>}
        </div>
    )
}