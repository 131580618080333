import React, { MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from "react";
import { DumbLoadingIndicator as LoadingIndicator } from "./LoadingIndicator";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import planData from '../data/plan-data'
import SubmitButton from "./SubmitButton";
import useOutsideClick from "@rooks/use-outside-click";
import CurrencyFormatter from 'currency-formatter'
import { Context } from '../store/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons'
import { faHeart as faHeartHollow } from '@fortawesome/free-regular-svg-icons'
import { faCamera } from '@fortawesome/free-solid-svg-icons'

var FileSaver = require('file-saver');


type Props = {
    onShowView:()=>void
}


export default function({onShowView}:Props){
    const app = useContext(Context);
    const ref = useRef<HTMLImageElement>(null);
    const planRef:MutableRefObject<HTMLDivElement> = useRef() as MutableRefObject<HTMLDivElement>;

    const salesData = app.state.data.sales!;
    
    const apartmentId = app.state.plan.apartmentId;
    const id = apartmentId ? planData.getPlan(apartmentId) : null;

    const [state, setState] = useState({
        src:null as null|string,
        visible:false,
        loading: false,
        apartmentId:null as null|string,
        srcClearTimeoutId:null as null|NodeJS.Timeout,
    });


    const onload = useCallback(() => {
        setState({
            ...state,
            loading: false,
        })
    }, [ref.current, state.loading])

    useEffect(() => {
        if(!state.loading && ref.current){
            ref.current.onload = () => {};
        }

        setState({
            ...state,
            loading: (id && state.src!=id) ? true : !!id,
            src: id ? `/JPG/${id}.jpg` : state.src,
            apartmentId: apartmentId || state.apartmentId,
            visible: !!id,
        })
    }, [id, apartmentId]);

    useEffect(() => {
        if(ref.current){
            setState({
                ...state,
                loading: !ref.current.complete
            })
        }
    }, [ref.current])


    useEffect(() => {
        if(state.loading && ref.current){
            ref.current.onload = onload;
        }
    }, [ref.current, state.loading])


    function onDownload(){
        const pdf = planData.getPDF(id!);
        if(pdf){
            FileSaver.saveAs(`/PDF/${pdf}`, pdf);
        }else{
            alert("PDF Could not be downloaded.")
        }
    }

    const price = state.apartmentId && CurrencyFormatter.format(salesData[state.apartmentId].price || 0, {
        symbol: '$',
        decimal: '.',
        thousand: ',',
        precision: 0,
        format: '%s%v' // %s is the symbol and %v is the value
    });

    // var loading = state.loading;
    var visible = state.visible;
    var src = state.src;

    // console.log("Src: " + src)

    var isFavourited = app.state.favourites.all.indexOf(apartmentId!)!=-1;

    function onFavouriteClicked(){
        if(app.state.favourites.all.indexOf(apartmentId!) == -1){
            app.actions.favourites.add(apartmentId!);
        }else{
            app.actions.favourites.remove(apartmentId!);
        }
    }

    function onViewClicked(){
        if(apartmentId != null){
            onShowView();
        }
    }

    function log(message:string){
        // console.log(message, state);
        return <></>;
    }

    function onClose(){
        app.actions.plan.hideApartmentPlan();
    }

    return (
        <div ref={planRef} className="Plan" data-visible={visible}>
            { src && 
                <TransformWrapper panning={{ velocityDisabled: true }}>
                    {({ state, zoomIn, zoomOut, resetTransform }) => (
                        <div>
                            <Resetter visible={visible} resetTransform={resetTransform}>
                                <img className="PlanImage" ref={ref} src={src!} alt="" width="1470px" height=" 980px"/>
                            </Resetter>
                            <div className="PlanUI" data-zoomed={state.scale!=1}>
                                <div className="PlanUITop">
                                    <div className="PlanTitle">APARTMENT {apartmentId}</div>
                                    <div className="PlanPrice">{price}</div>
                                    <div className="PlanButtons">
                                        {/* <SubmitButton title={<><FontAwesomeIcon icon={faCamera} /> VIEW</>} onClick={onViewClicked} invert={true}/> */}
                                        <SubmitButton title={isFavourited ? <span><FontAwesomeIcon icon={faHeartSolid} /> REMOVE FROM FAVOURITES</span> : <span><FontAwesomeIcon icon={faHeartHollow} /> ADD TO FAVOURITES</span>} onClick={onFavouriteClicked} invert={true} />
                                        <SubmitButton title="DOWNLOAD" onClick={onDownload} />
                                    </div>
                                </div>
                                <div onClick={()=>zoomIn()} className="PlanZoomIn"><img src={require('../images/interface/icon--zoom-in.png').default} alt="Zoom in" /></div>
                                <div onClick={()=>zoomOut()} className="PlanZoomOut"><img src={require('../images/interface/icon--zoom-out.png').default} alt="Zoom out" /></div>
                            </div>
                        </div>
                    )}
                </TransformWrapper>
                    
            }
            <div className="PlanClose" onClick={onClose}><img src={require('../images/interface/btn--close-residences.png').default} /></div>
        </div>
    )
}

/*
function downloadFile(fileName:string) {
    FileSaver.saveAs(`/pdfs/${fileName}`, fileName);
}
*/

function Resetter({visible, resetTransform, children}:{visible:boolean, resetTransform:()=>void, children:React.ReactNode}){
    const [changes, setChanges] = useState(0);

    useEffect(() => {
        if(changes>0 && !visible){
            resetTransform();
        }
    }, [changes, visible]);

    useEffect(() => {
        setChanges(changes+1);
    }, [visible])

    // console.log("Visible: " + visible, changes);
    return (
        <TransformComponent>
            { children }
        </TransformComponent>
    )
}