import { useContext, useEffect, useState } from "react"
import { Context } from '../store/store'
import Field from "./Field";
import Rules from '../helper/rules'
import { apiFetch } from "../helper/api";
import SubmitButton from "./SubmitButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import PlanData from '../data/plan-data'
import Checkbox from "./Checkbox";


const rules = {
    firstName: [
        Rules.enforce.required("First name is required.")
    ],
    lastName: [
        Rules.enforce.required("Last name is required.")
    ],
    phone: [
        Rules.enforce.required("Phone number is required.")
    ],
    email: [
        Rules.enforce.required("Email is required."),
        Rules.enforce.email("Please enter a valid email.")
    ],
    postcode: [
        Rules.enforce.required("Postcode is required.")
    ],
    sendTax: [],
    sendBodyCorp: [],
}


const defaultFields = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    postcode: "",
    sendTax: false,
    sendBodyCorp: false,
}


export default function (){
    const [state, setState] = useState({
        fields: defaultFields,
        errors: null as null | Record<keyof typeof defaultFields, string[]>,
        submissions: 0,
        successes: 0,
        collapsedEmail: true,
    })

    const app = useContext(Context)
    const visible = app.state.favourites.show;

    function setField(name:keyof typeof defaultFields, value:string|boolean){
        setState({
            ...state,
            fields:{
                ...state.fields,
                [name]: value,
            }
        })
    }


    useEffect(function(){
        setState({
            ...state,
            collapsedEmail: true,
        })
    }, [app.state.user.username]);


    useEffect(function(){
        setState({
            ...state,
            collapsedEmail: true,
        })
    }, [app.state.favourites.show])


    function setErrors(errors:typeof state['errors']){
        setState({
            ...state,
            errors
        })
    }


    function onSubmitPressed(){
        const errors = Rules.getErrors(state.fields, rules);
        if(errors){
            setErrors(errors);
        }else{
            setState({
                ...state,
                errors:null,
                submissions: state.submissions+1,
            })
        }
    }


    useEffect(function(){
        async function emailFavourites(){
            // console.log("About to submit favourites");
            // console.log(app.state.favourites.all);
            const response = await apiFetch('https://cms.oriaspringhill-app.com.au/api/app-favourites.php', {
                apartmentIds: app.state.favourites.all,
                email: state.fields.email,
                user_name: app.state.user.username,
                password: app.state.user.password,
                sendTax: state.fields.sendTax,
                sendBodyCorp: state.fields.sendBodyCorp,
            })

            if(response.ok){
            }else{
                throw("An error occured");
            }
        }

        async function submitEnquiryForm(){
            const response = await apiFetch('https://cms.oriaspringhill-app.com.au/api/app-enquire.php', {
                first_name: state.fields.firstName,
                last_name: state.fields.lastName,
                phone: state.fields.phone,
                email: state.fields.email,
                postcode: state.fields.postcode,
                apartmentIds: app.state.favourites.all,
                user_name: app.state.user.username,
                password: app.state.user.password,
            })

            if(response.ok){
            }else{
                throw("An error occured");
            }
        }

        async function submit(){
            try{
                await submitEnquiryForm();
                await emailFavourites();
                setState({
                    ...state,
                    successes: state.successes + 1,
                    fields: defaultFields,
                })
                alert("Enquiry was submitted.")
            }catch(e){
                alert("An error has occured.");
            }
        }

        if(state.submissions > 0){
            submit();
        }
    }, [state.submissions])

    /*
    useEffect(function(){
        if(state.successes > 0){
            app.actions.favourites.reset();
        }
    }, [state.successes]);
    

    useEffect(function(){
        if(state.successes > 0){
            app.actions.favourites.hide();
        }
    }, [state.successes]);
    */


    function onClose(){
        app.actions.favourites.hide();
    }

    function onShowHide(){
        setState({
            ...state,
            collapsedEmail: !state.collapsedEmail,
        })
    }

    function onRemoveFavourite(apartmentId:string){
        app.actions.favourites.remove(apartmentId);
    }


    function onClearFavourites(){
        app.actions.favourites.reset();
    }


    function showPlan(apartmentId:string){
        app.actions.plan.showApartmentPlan(apartmentId);
    }


    return (
        <div className="Favourites" data-showing={!!visible}>
            <div className="FavouritesMargin">
                <div className="FavouritesClose" onClick={onClose}><img src={require('../images/interface/btn--close-residences.png').default} /></div>
                <div className="FavouritesTop">
                    <h1>FAVOURITES</h1>
                    { app.state.favourites.all.length > 0 && 
                    <div className="ShowHideEmail" onClick={onShowHide}><span className="FavouriteEmailIcon"><FontAwesomeIcon icon={faEnvelope}/></span>{state.collapsedEmail ? 'Show Email Plans' : 'Close Email Plans'}</div>
                    }
                    { app.state.favourites.all.length > 0 &&
                        <div className="ClearFavourites" onClick={onClearFavourites}><span className="FavouriteEmailIcon"><FontAwesomeIcon icon={faTrash}/></span>Clear favourites</div>
                    }
                </div>
                <div className="FavouritesForm" data-collapsed={app.state.favourites.all.length == 0 || state.collapsedEmail}>
                    <div>
                        <Field name="firstName" placeholder="First Name" value={state.fields.firstName}   errors={state.errors?.firstName} type="text"   onChange={setField} />
                        <Field name="lastName"  placeholder="Last Name"  value={state.fields.lastName}    errors={state.errors?.lastName}  type="text"   onChange={setField} />
                    </div><div>
                        <Field name="phone"     placeholder="Phone"      value={state.fields.phone}       errors={state.errors?.phone}     type="tel"    onChange={setField} />      
                        <Field name="postcode"  placeholder="Postcode"   value={state.fields.postcode}    errors={state.errors?.postcode}  type="number" onChange={setField} />
                    </div><div>
                       <Field name="email"     placeholder="E-mail"     value={state.fields.email}       errors={state.errors?.email}     type="email"  onChange={setField} />
                    </div>
                    <div style={{display:'flex'}}>
                        <div>
                            <Checkbox name="sendBodyCorp" value={state.fields.sendBodyCorp} label="Body Corporate Fees" onChange={setField} />
                            <Checkbox name="sendTax" value={state.fields.sendTax} label="Tax Depreciation Estimate" onChange={setField} />
                        </div>
                        <div style={{textAlign:'center', width:'400px', marginLeft:'60px'}}>
                            <SubmitButton title="SUBMIT" onClick={onSubmitPressed} />
                        </div>
                    </div>
                </div>
                <div className="FavouritesThumbs">
                    { app.state.favourites.all.map(apartmentId => (
                        <div className="FavouritesThumb">
                            <div className="FavouritesThumbTitle"><span>{apartmentId}</span><span className="FavouritesThumbTitleClose" onClick={()=>onRemoveFavourite(apartmentId)}><img src={require('../images/interface/btn--close-thumb.png').default} /></span></div>
                            <div className="FavouritesThumbImageBorder" onClick={() => showPlan(apartmentId)}>
                                <img className="FavouritesThumbImage" src={`${process.env.PUBLIC_URL}/thumbs/${PlanData.getPlan(apartmentId)}.jpg`} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
