import Zoom from '../components/Zoom'
import ImageLoader from '../components/ImageLoader'


export default function(){
    return (
        <div>
            <Zoom showButtons={true}>
                <ImageLoader width={1920} height={1080} src={require("../images/location/bkg--location-3.jpg").default} />    
            </Zoom>
        </div>
    )
}
