import React from "react"

type Props = {
    title?:React.ReactNode,
    onClick:Function,
    invert?:Boolean,
}


export default function({onClick,title,invert}:Props){
    return (
        <div className="SubmitButton" data-invert={invert} onClick={(e) => onClick()}>
            <div className="SubmitButtonTitle">
                {title || 'SUBMIT'}
            </div>
        </div>
    )

}