import { useEffect, useRef, useState } from 'react'
import { useGesture } from 'react-use-gesture'
import { Tween } from 'react-gsap'
import ImageLoader from './ImageLoader'
import SlideReveal from './SlideReveal'


type Props = {
    selection: string|null,
    carousel:boolean,
    disableSwipe?:boolean,
    onSelected: (item:string|null)=>void,
    items:{
        type?: "VIDEO"|"IMAGE",
        src: string,
        src2?: string,
        caption: string,
        caption2?: string,
    }[]
}


const LightboxItemContainer = ({children, items, selectionIndex, item}:{children:React.ReactNode, items:Props['items'], selectionIndex:number|null, item:Props['items'][number]}) => {
    const [state, setState] = useState({
        prevSelectionIndex: selectionIndex,
        offsetXX: 0,
        startOffsetXX: 0,
        allowTransition: false,
        time: Date.now(),
    })

    const ref = useRef<HTMLDivElement>(null);

    useEffect(function(){
        setState({
            ...state,
            allowTransition: state.prevSelectionIndex!=null,
            startOffsetXX: state.offsetXX,
            prevSelectionIndex: selectionIndex,
            offsetXX: (selectionIndex===null ? state.offsetXX : (selectionIndex || 0) * 1920),
            time: Date.now(),
        })
    }, [selectionIndex])

    const styleCaption = {
        width: ref.current?.clientWidth! + 'px'
    }

    const style = {
        'transform': `translate3d(-${state.offsetXX}px, 0, 0)`
    }


    return (
        <Tween to={style} duration={state.allowTransition?0.5:0} ease='quad.inOut'>
            <div className="LightboxItem" style={style} data-transition={state.allowTransition}>
                <div className="LightboxItemImageContainer">
                    <div ref={ref}>
                        { children }
                    </div>
                    <div className="LightboxCaptions" style={styleCaption} data-spread={!!item.caption2}>
                        <div className="LightboxItemCaption">{item.caption}</div>
                        { item.caption2 && <div className="LightboxItemCaption">{item.caption2}</div> }
                    </div>
                </div>
            </div>
        </Tween>
    )
}



const LightboxItem = (props:{items:Props['items'], selectionIndex:number|null, item:Props['items'][number]}) => {
    return (
        <LightboxItemContainer {...props}>
            { props.item.src2
            ? <SlideReveal childA={<ImageLoader src={props.item.src} />} childB={<ImageLoader src={props.item.src2} />}/>
            : props.item.type=="VIDEO"
            ? <VideoItem src={props.item.src} show={props.selectionIndex!=null}/>
            : <ImageLoader src={props.item.src} />
            }
        </LightboxItemContainer>
    )
}


const VideoItem = (props:{src:string, show:boolean}) => {
    if(props.show==false) return null;
    return (
        <iframe width="1700" height="980" src={props.src} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
    )
}


export default function({selection, onSelected, items, carousel, disableSwipe}:Props){
    const bindGesture = useGesture({
        onDragEnd: state => {
            if(!carousel) return;
            if(disableSwipe) return;
            
            if(state.movement[0] > 5 && hasPrev){
                onSelected(items[selectionIndex!-1].src);
            } 
            
            if(state.movement[0]< -5 && hasNext){
                onSelected(items[selectionIndex!+1].src);
            } 
        }
    })


    const selectionIndex = indexOf(selection);
    
    function onClose(){
        onSelected(null);
    }

    function onNext(){
        if(selectionIndex != null){
            onSelected(items[selectionIndex+1].src);
        }
    }

    function onPrev(){
        if(selectionIndex != null){
            onSelected(items[selectionIndex-1].src);
        }
    }

    function indexOf(selection:string|null):number|null{
        if(selection == null) return null;
        for(var itemId in items){
            var item = items[itemId];
            if(item.src === selection) return items.indexOf(item)
        };
        throw(new Error("Could not find index: " + selection));
    }

    const hasPrev = selectionIndex!=null && selectionIndex > 0;
    const hasNext = selectionIndex!=null && selectionIndex < items.length-1;

    return (
        <div className="Lightbox" data-fade={selection!=null}>
            <div className="LightboxCover"/>
            <div className="LightboxContent" {...bindGesture()}>
                { items.map(item => <LightboxItem items={items} item={item} selectionIndex={selectionIndex} key={item.src}/>) }
            </div>
            <div className="LightboxClose" onClick={onClose}><ImageLoader src={require('../images/interface/icon--hide-thumb.png').default} /></div>
            <div className="LightboxNext" onClick={onNext} data-hide={!carousel || !hasNext}><ImageLoader src={require('../images/interface/icon--next.png').default} /></div>
            <div className="LightboxPrev" onClick={onPrev} data-hide={!carousel || !hasPrev}><ImageLoader src={require('../images/interface/icon--previous.png').default} /></div>
        </div>
    )
}