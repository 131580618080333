import { useState } from 'react'
import Lightbox from '../components/Lightbox'
import ThumbnailPicker from '../components/ThumbnailPicker'
import ImageLoader from '../components/ImageLoader'


const items = [
    { src:require("../images/interiors/img--living.jpg").default, caption:"LIVING", x:598, y:182, width:394, height:398 },
    { src:require("../images/interiors/img--bedroom.jpg").default, caption:"TERRACE & LIVING", x:1012, y:182, width:394, height:398 },
    { src:require("../images/interiors/img--bedroom-2.jpg").default, caption:"LIVING", x:1426, y:182, width:394, height:398 },
    { src:require("../images/interiors/S020_KITCHEN_DARK_FINAL1500.jpg").default, src2:require("../images/interiors/S010_KITCHEN_LIGHT_FINAL1500.jpg").default, caption:"Light", caption2:"Dark", x:598, y:598, width:676, height:399 },
    { src:require("../images/interiors/S030_BATHROOM_DARK_FINAL1500.jpg").default, src2:require("../images/interiors/S040_BATHROOM_LIGHT_FINAL1500.jpg").default, caption:"Light", caption2:"Dark", x:1292, y:598, width:528, height:399 },
]


export default function(){
    const [selection, setSelection] = useState<string|null>(null);

    return (
        <div>
            <ThumbnailPicker onSelected={setSelection} items={items} debug={false}/>
            <Lightbox items={items} selection={selection} onSelected={setSelection} carousel={true} disableSwipe={true} />
            <ImageLoader src={require("../images/interiors/bkg--interiors-schemes.jpg").default} />
        </div>
    )
}