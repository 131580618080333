import * as React from 'react'

type Props = {
    x:number,
    y:number,
    width:number,
    height:number,
    onClick:()=>void,
    debug?:boolean,
}

export default function Hotspot(props:Props){
    const {x, y, width, height, debug, onClick} = props;
    
    const style:React.CSSProperties = {
        position: 'absolute',
        left: x,
        top: y,
        width,
        height,
        backgroundColor: debug ? 'rgba(255, 0, 0, 0.3)' : 'default',
    }

    return <div className="hotspot" style={style} onClick={onClick}></div>
}