import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { createImmerContext } from '../store/utils'
import LoadingIndicator from './LoadingIndicator'
import { Context as StoreContext } from '../store/store'
// import lighting from '../lighting';

const initialState = {
    loading: {} as Record<string, boolean>,
    isLoading: false,
}

export type State = typeof initialState;

const startLoading = (id:string) => (state:State) => {
    state.loading[id] = true;
    state.isLoading = Object.keys(state.loading).length > 0;
}


const endLoading = (id:string) => (state:State) => {
    if(state.loading[id]) delete state.loading[id]
    state.isLoading = Object.keys(state.loading).length > 0;
}

const clear = () => (state:State) => {
    state.loading = {};
    state.isLoading = false;
}


export const { Provider, Context } = createImmerContext(initialState, {
    startLoading,
    endLoading,
    clear,
})


function Child({children}:{children:React.ReactNode}){
    useEffect(() => {
        return () => {
            clear();
        }
    })

    return (
        <Context.Consumer>
            {
                (state) => ( children )
            }
        </Context.Consumer>
    )
}


export default function({children, loginRequired, preloadRequired}:{loginRequired:boolean, preloadRequired:boolean, children:React.ReactNode}){
    const store = useContext(StoreContext);
    const history = useHistory();

    useEffect(function(){
        if(loginRequired && store.state.user.username == null){
            history.replace("/");
        }
    }, [loginRequired, store.state.user.username])


    const ref = useRef<HTMLDivElement>(null)
    const [state, setState] = useState({
        init:true,
    })

    useEffect(() => {
        const timer = setTimeout(function(){
            if(state.init){
                setState({
                    ...state,
                    init:false,
                })
            }
        }, 30);

        return () => {
            clearTimeout(timer);
        }
    }, [state])

    useEffect(() =>{
        if(ref.current) ref.current.focus();
    }, []);

    return (
        <div className="AppRoute" data-init={state.init} ref={ref}>
            <div className="AppClipper">
                <div className="AppRouteCover" />
                <Provider>
                    <LoadingIndicator required={preloadRequired}>
                        <Child>{ children }</Child>
                    </LoadingIndicator>
                </Provider>
            </div>
        </div>
    )
}
