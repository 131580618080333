import _ from 'lodash';
import React from 'react'
import { useContext } from 'react';
import { useEffect } from 'react';
import lighting from '../lighting';
import { Context } from '../store/store';


export default function LightingHandler({children}:{children:React.ReactNode}){
    const app = useContext(Context);
    const selectedApartmentId = app.state.plan.apartmentId;

    // Set the lighting selection to the selected apartment plan
    useEffect(()=>{
        app.actions.lighting.set(selectedApartmentId);
    }, [selectedApartmentId]);


    // When favourites are shown, hide the selected apartment plan
    useEffect(() => {
        if(app.state.favourites.show){
            app.actions.plan.hideApartmentPlan(); 
        }
    }, [app.state.favourites.show])


    // Set the lighting to no selection when the user logs out
    useEffect(()=>{
        if(app.state.user.username){
            app.actions.lighting.set(null)
        }else{
            app.actions.lighting.set('all')
        }
    }, [app.state.user.username])


    // Set the lighting on the actual rig when the lighting selection changes
    useEffect(()=>{
        if(app.state.lighting.selection=='all'){
            lighting.allOn()
        }else{
            lighting.allOff();
            if(app.state.lighting.selection!=null){
                lighting.turnOnGroup(app.state.lighting.selection);
            }
        }
    }, [app.state.lighting.selection]);


    // Old logic. Delete this once the new lighting logic has been verified.
    /*
    // Turn light on for a single selected apartment
    useEffect(()=>{
        try{           
            if(selectedApartmentId!=null){
                lighting.turnOnGroup(selectedApartmentId)
            }
        }catch(e){}
    }, [selectedApartmentId]);

    // When an apartment if show
    useEffect(()=>{
        app.actions.lighting.allOff();
    }, [selectedApartmentId]);


    useEffect(() => {
        if(app.state.favourites.show){
            app.actions.plan.hideApartmentPlan();
        }
    }, [app.state.favourites.show])


    useEffect(()=>{
        lighting.allOff();
    }, [app.state.user.username])


    // The effect that controls the lighting rig
    useEffect(() => {
        if(app.state.lighting.allOn){
            lighting.allOn()
        }else{
            lighting.allOff();
        }
    }, [app.state.lighting.allOn])
    */

    return <>{children}</>;
}