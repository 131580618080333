export type State = { render: typeof initialState };


const initialState = {
    src: null as null|string,
    caption: null as null|string,
}


const showRender = (src:string, caption:string) => (state:State) => {
    state.render.src = src;
    state.render.caption = caption;
}


const hideRender = () => (state:State) => {
    state.render.src = null;
    state.render.caption = null;
}


export default {
    initialState,
    actions:{
        showRender,
        hideRender,
    }
}
