import { Provider } from '../store/store'
import Routes from './Routes';
import ScaleContainer from './ScaleContainer'
import Mockup from './Mockup'
import Nav from './Nav'
import { HashRouter as Router } from "react-router-dom";
import SalesDataLoader from './SalesDataLoader';
import Favourites from './Favourites';
import LightingHandler from './LightingHandler';


function App() {
	return (
		<div className="App">
			<Provider>
				<ScaleContainer>
					<LightingHandler>
						<SalesDataLoader>
							<Router>
								<Mockup />
								<Nav />
								<Routes />
							</Router>
							<Favourites/>
						</SalesDataLoader>
					</LightingHandler>
				</ScaleContainer>
			</Provider>
		</div>
	);
}

export default App;
