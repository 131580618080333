import { useState } from 'react'
import Lightbox from '../components/Lightbox'
import ThumbnailPicker from '../components/ThumbnailPicker'
import ImageLoader from '../components/ImageLoader'


const items = [
    { src:require("../images/sub-penthouses/img--SubPenthouse-Living-Dining.jpg").default, caption:"LIVING & DINING", x:597, y:184, width:1223, height:258 },
    { src:require("../images/sub-penthouses/img--SubPenthouse_Media.jpg").default, caption:"MEDIA", x:597, y:462, width:653, height:258 },
    { src:require("../images/sub-penthouses/img--SubPenthouse_Bar_Lounge.jpg").default, caption:"BAR LOUNGE", x:1270, y:462, width:550, height:258 },
    { src:require("../images/sub-penthouses/img--SubPenthouse-Kitchen.jpg").default, caption:"KITCHEN", x:597, y:740, width:653, height:258 },
    { src:require("../images/sub-penthouses/img--SubPenthouse_Ensuite.jpg").default, caption:"ENSUITE", x:1270, y:740, width:550, height:258 },
]


export default function(){
    const [selection, setSelection] = useState<string|null>(null);

    return (
        <div>
            <ThumbnailPicker onSelected={setSelection} items={items} debug={false}/>
            <Lightbox items={items} selection={selection} onSelected={setSelection} carousel={true} disableSwipe={true} />
            <ImageLoader src={require("../images/sub-penthouses/bkg--sub-penthouses.jpg").default} />
        </div>
    )
}