import useOutsideClick from "@rooks/use-outside-click";
import { useRef, useState } from "react";
import ImageLoader from "./ImageLoader";

type Props<T> = {
    label:React.ReactNode,
    selected:T,
    onSelection: (value:T) => void,
    options:{
        value:T,
        label:React.ReactNode,
    }[]
}


export default function<T>({label, options, selected, onSelection}:Props<T>){
    const componentRef = useRef<HTMLDivElement>(null!);
    const [ state, setState ] = useState({
        expanded: false
    })

    const selectedItem = options.find(option => option.value == selected)

    useOutsideClick(componentRef, function(){
        collapse();
    }, state.expanded)
    
    function expand(){
        setState({
            ...state,
            expanded: true
        })
    }

    function collapse(){
        setState({
            ...state,
            expanded: false
        })
    }

    function onSelectionClicked(option:Props<T>['options'][number]){
        return function(){
            collapse();
            onSelection(option.value);
        }
    }


    return (
        <div className="Selection" data-expanded={state.expanded} ref={componentRef}>
            <div className="SelectionLabel">{label}</div>
            <div className="SelectionSelector">
                <div className="SelectionEmpty">
                    <div className="SelectionItem">Empty</div>
                </div>
                <div className="SelectionSelect" onClick={expand}>
                    <div className="SelectionItem" data-enabled={false}>{selectedItem?.label}</div>
                </div>
                <div className="SelectionOptions">
                    <div>
                        { options.map(option => (
                            <div className="SelectionOption" onClick={onSelectionClicked(option)}>
                                <div className="SelectionItem">{option.label}</div>
                            </div>
                        )) }
                    </div>
                    <div className="SelectionIcon">
                        <div className="SelectionIconClose"><ImageLoader src={require('../images/interface/btn--submenu-menu.png').default} /></div>
                    </div>
                </div>
                <div className="SelectionIcon">
                    <div className="SelectionIconOpen"><ImageLoader src={require('../images/interface/btn--submenu-menu.png').default} /></div>
                </div>
            </div>
        </div>
    )
}
