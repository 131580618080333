import { useState } from 'react';
import Hotspot from '../components/Hotspot'
import ImageLoader from '../components/ImageLoader'
import Lightbox from '../components/Lightbox';

const items = [
    { type:"VIDEO" as const, src:"https://www.youtube.com/embed/uzyjcQF5s0U", caption:"", x:640, y:200, width:384, height:500 },
    { type:"VIDEO" as const, src:"https://www.youtube.com/embed/YVjpwysHfmw", caption:"", x:640, y:200, width:384, height:500 },
    { type:"VIDEO" as const, src:"https://www.youtube.com/embed/o3_6TWJVN78", caption:"", x:640, y:200, width:384, height:500 },    
]



export default function(){
    const [selection, setSelection] = useState<string|null>(null);

    return (
        <div>
            <ImageLoader src={require("../images/team/bkg--team-1-STAGE_3.jpg").default} width={1920} height={1080} />
            <Hotspot debug={false} x={680} y={860} width={140} height={70} onClick={()=>setSelection(items[0].src)}/>
            <Hotspot debug={false} x={1200} y={840} width={140} height={70} onClick={()=>setSelection(items[1].src)}/>
            <Hotspot debug={false} x={1200} y={450} width={140} height={70} onClick={()=>setSelection(items[2].src)}/>
            <Lightbox items={items} selection={selection} onSelected={setSelection} carousel={false}/>
        </div>
    )
}