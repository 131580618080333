
type Props = {
    label:React.ReactNode,
    icon?:React.ReactNode,
    onClick:()=>void
}


export default function({label, onClick, icon}:Props){
    return (
        <div className="SidePanelButton" onClick={onClick}>
            <span className="SidePanelButtonTitle">{label}</span>{icon && <span className="SidePanelButtonIcon">{icon}</span>}
        </div>
    )
}