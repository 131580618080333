import React, { useContext, useEffect } from "react";
import { useInterval } from "react-use";
import { fetchSalesData } from "../data/sales-data";
import { Context } from "../store/store";


export default function({children}:{children:React.ReactNode}){
    const {state, actions} = useContext(Context);
    useInterval(doRequest, 1000 * 60 * 5);

    useEffect(() => {
        if(state.user.username != null){
            doRequest();
        }
    }, [state.user.username])

    useEffect(() => {
        if(state.data.requestSales && state.user.username != null){
            doRequest();
        }
    }, [state.data.requestSales]);


    async function doRequest(){
        try{
            const data = await fetchSalesData(state.user.username!, state.user.password!);
            actions.data.setSalesData(data);
        }catch(e){
            //alert("An error occured");
            throw(e);
        }
    }

    return <>{ children }</>
}