import { apiFetch } from "../helper/api";

export type SalesData = Record<string, {
    bed: number,
    price: number,
    floor: number,
    unitNumber: number,
    status: string, //"" | "Available" | "Sold" | "Reserved", // Any others?
    saleStatus: string, //"Stock" | "Contract Exchange" | "Held by Agent" | "Held by Vendor" | "Contract Issued" | "Conditional Exchange" | "Reserved", // Any others?
}>;


export async function fetchSalesData(username:string, password:string){
    const response = await apiFetch("https://cms.oriaspringhill-app.com.au/api/app-get-live-data-json-2.php", {
        user_name: username,
        password,
        page: 0,
    })
    const data:any = await response.json();

    const result:SalesData = {};
    Object.keys(data).forEach(function(key){
        const unitNumber = data[key].unitNumber;
        result["" + unitNumber] = data[key];
    })

    result[207].bed = 99;
    result[208].bed = 99;
    result[307].bed = 99;
    result[308].bed = 99;


    return result;

    /*

    var numLevels = 13;
    var numRooms = 30;

    for(var l = 1; l <= numLevels; l++){
        data[l] = [];
        for(var r=0; r<numRooms; r++){
            data[l][r] ={
                rooms: ['1','1f','2','2f','3','4'][Math.floor(Math.random()*6)]!,
                price: (Math.floor(100 * (Math.random() * 8)) * 1000) + 600000,
                sold: false,
                res: false,
                //sold: Math.random() < 0.3,
                //res: Math.random() < 0.3,
            }
        }
    }

    return data;
    */
}