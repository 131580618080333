import React, { Children, useState } from 'react'
import { Tween } from 'react-gsap'
import { useInterval } from 'react-use'


type Props = {
    children:React.ReactNode
}



export default function({children}:Props){
    const [state, setState] = useState({
        index:0,
    })

    useInterval(() => {
        if(count != 1){
            setState({
                ...state,
                index: state.index > 10000 ? 0 : state.index+1, // Hacky
            })
        }
    }, 4000)

    const count = Children.count(children);

    const trans = {
        'transform': `translate3d(-${1920*state.index}px, 0, 0)`
    }

    return (

        <div className="AutoCarousel">
            <Tween to={trans} duration={0.9} ease='quad.inOut'>
                {
                    Children.map(children, (node, i) => {
                        var a = i-state.index;
                        while(a < -1){
                            a += count;
                        }

                        const style = {
                            'left':((a*1920) + (1920*state.index))+'px',
                        }

                        return (
                            <div className="AutoCarouselItem" style={style}>{node}</div>
                        )
                        
                    }
                )}
              </Tween>
            
        </div>
    )
}
