import plan from "./plan"

export type State = { favourites: typeof initialState, plan: typeof plan.initialState }

const initialState = {
    show: false,
    all:[] as string[]
}


const add = (apartmentId:string) => (state:State) => {
    if(state.favourites.all.indexOf(apartmentId) == -1){
        state.favourites.all.push(apartmentId)
    }
}


const remove = (apartmentId:string) => (state:State) => {
    if(state.favourites.all.indexOf(apartmentId) != -1){
        state.favourites.all.splice(state.favourites.all.indexOf(apartmentId), 1);
    }
}

const reset = () => (state:State) => {
    // console.log("Reset favourites");
    state.favourites.all = [];
}


const show = () => (state:State) => {
    state.favourites.show = true;
}


const hide = () => (state:State) => {
    state.favourites.show = false;
}


export default {
    initialState,
    actions:{
        add,
        remove,
        reset,
        show,
        hide,
    }
}
