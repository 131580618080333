import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'


type Props = {
    sold:boolean,
    res:boolean,
    render:boolean,
    title:string,
    favourited:Boolean
}


export default function ({sold, res, title, favourited, render}:Props){
    
    if(render){
        const icon = (title.indexOf('plan--') > -1) ? 'icon--plan-hotspot' : 'icon--render-hotspot'

        return (
            <div className="RenderIndicator">
                <img src={require(`../images/interface/${icon}.png`).default} />
            </div>
        )
    }
    
    if(!sold && !res) return (
        <div className="SaleIndicatorJustText">
            {favourited && <div className="SaleIndicatorHeart"><FontAwesomeIcon icon={faHeart} size='3x'/></div> }
            <div className="SaleIndicatorJustTextText" data-favourited={favourited}>{title}</div>
        </div>
    );
    

    return (
        <div className="SaleIndicator" data-res={res} data-sold={sold} data-render={render}>
            <div>{ sold ? "SOLD" : res ? "RES" : ""}</div>
        </div>
    )
}