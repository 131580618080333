import lighting from "../lighting";
import favourites from "./favourites";

export type State = { plan: typeof initialState, favourites: typeof favourites.initialState  };


const initialState = {
    apartmentId: null as null|string
}


const showApartmentPlan = (item:string) => (state:State) => {
    state.plan.apartmentId = item;
    state.favourites.show = false;
}


const hideApartmentPlan = () => (state:State) => {
    state.plan.apartmentId = null;
}


export default {
    initialState,
    actions:{
        showApartmentPlan,
        hideApartmentPlan,
    }
}
