import { createImmerContext } from './utils'
import errors from '../core/errors'
import fields from '../core/fields'
import menu from '../core/menu'
import user from '../core/user'
import data from '../core/data'
import plan from '../core/plan'
import render from '../core/render'
import favourites from '../core/favourites'
import lighting from '../core/lighting'


export const { Provider, Context } = createImmerContext(
    {
        errors: errors.initialState,
        fields: fields.initialState,
        menu: menu.initialState,
        user: user.initialState,
        data: data.initialState,
        favourites: favourites.initialState, 
        plan: plan.initialState,
        render: render.initialState,
        lighting: lighting.initialState,
    },{
        errors: errors.actions,
        fields: fields.actions,
        menu: menu.actions,
        user: user.actions,
        data: data.actions,
        favourites: favourites.actions,
        plan: plan.actions,
        render: render.actions,
        lighting: lighting.actions,
    }
);
