import lighting from "../lighting";

export type State = { user: typeof initialState };

const initialState = {
    username: null as string|null,
    password: null as string|null,
}


const setUser = (username:string, password:string) => (state:State) => {
    state.user.username = username;
    state.user.password = password;
}



const logout = () => (state:State) => {
    // console.log("Logout");
    state.user.username = null;
    state.user.password = null;
}


export default {
    initialState,
    actions:{
        setUser,
        logout,
    }
}
