export type State = { lighting: typeof initialState  };

type Selection = null|string|"all"


const initialState = {
    selection: null as Selection
}


const set = (selection:Selection) => (state:State) => {
    state.lighting.selection = selection;
}


export default {
    initialState,
    actions:{
        set
    }
}
