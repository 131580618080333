import ImageLoader from '../components/ImageLoader'
import Zoom from '../components/Zoom'


export default function(){
    return (
        <div>
            <Zoom showButtons={false}>
                <ImageLoader width={1920} height={1080} src={require("../images/location/bkg--location-2.jpg").default} />
            </Zoom>
        </div>
    )
}