import Floorplan from '../components/Floorplan'
import SidePanel from '../components/SidePanel'
import Selection from '../components/Selection'
import { useContext, useEffect, useState } from 'react'
import LevelSelector from '../components/LevelSelector'
import coordData from '../data/coord-data'
import { SalesData } from '../data/sales-data'
import Loader from '../components/Loader'
import Plan from '../components/Plan'
import { Context } from '../store/store'
import SidePanelButton from '../components/SidePanelButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart as faHeartHollow } from '@fortawesome/free-regular-svg-icons'
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons'
import { faLightbulb as faLightbulbSolid } from '@fortawesome/free-solid-svg-icons'
import { faLightbulb as faLightbulbHollow } from '@fortawesome/free-regular-svg-icons'
import Lightbox from '../components/Lightbox'




const optionsType = [
    {
        value:-1,
        label:"Any",
    },{
        value:2,
        label:"2 Bedrooms",
    },{
        value:3,
        label:"3 Bedrooms",
    },{
        value:4,
        label:"4 Bedrooms",
    },{
        value:99,
        label:"Dual Key",
    },
]

const optionsPriceRange = [
    {
        value:"Any",
        label:"Any",
    },{
        value:"$400k - $499k",
        label:"$400k - $499k",
    },{
        value:"$500k - $599k",
        label:"$500k - $599k",
    },{
        value:"$600k - $699k",
        label:"$600k - $699k",
    },{
        value:"$700k - $999k",
        label:"$700k - $999k",
    },{
        value:"$1M+",
        label:"$1M+",
    },
]



function getMinMax(str:string):[number,number]{
    switch(str){
        case "Any": return [0,9999999];
        case "$400k - $499k": return [400,500];
        case "$500k - $599k": return [500,600];
        case "$600k - $699k": return [600,700];
        case "$700k - $999k": return [700,1000];
        case "$1M+": return [1000,999999];
        default: throw(new Error("Not found: " + str));
    }
}

const optionsLevels = [
    {
        label:'Rooftop',
        value: 16,
        level: -1,
    },{
        label:'Level 15',
        value: 15,
        level: 15,
    },{
        label:'Level 14',
        value: 14,
        level: 14,
    },{
        label:'Level 13',
        value: 13,
        level: 13,
    },{
        label:'Level 12',
        value: 12,
        level: 12,
    },{
        label:'Level 11',
        value:11,
        level: 11,
    },{
        label:'Level 10',
        value:10,
        level: 10,
    },{
        label:'Level 9',
        value: 9,
        level: 9,
    },{
        label:'Level 8',
        value: 8,
        level: 8,
    },{
        label:'Level 7',
        value: 7,
        level: 7,
    },{
        label:'Level 6',
        value: 6,
        level: 6,
    },{
        label:'Level 5',
        value: 5,
        level: 5,
    },{
        label:'Level 4',
        value: 4,
        level: 4,
    },{
        label:'Level 3',
        value: 3,
        level: 3,
    },{
        label:'Level 2',
        value: 2,
        level: 2,
    },{
        label:'Ground',
        value: 1,
        level: 1,
    },
]

const NUM_LEVELS = optionsLevels.length;
const NUM_FRAMES = 35;


function getImages(){
    const images = new Array<Array<HTMLImageElement>>();
    for(var level=0; level<NUM_LEVELS; level++){
        const levelImages = new Array<HTMLImageElement>();
        images.push(levelImages);
        var levelStr = (level+1)<=9 ? "0"+(level+1) : (level+1);
        for(var frame=0; frame<=NUM_FRAMES; frame++){
            var frameStr = frame<=9 ? "0"+frame : frame;
            const image = new Image();
            levelImages.push(image);
            var src= `${process.env.PUBLIC_URL}/floorplans/3d_Floorplans_${levelStr}/3d_Floorplans_${levelStr}_${frameStr}.jpg`
            setTimeout(setImageSource(image, src), 300);
        }
    }
    return images;
}


function setImageSource(image:HTMLImageElement, src:string){
    return function(){
        image.src = src
    }
}


const preloadObject = {
    src:"floor-plans",
    complete:false,
    onload:function(){}
}


const images = getImages();

var loaded = 0;
images.flat().forEach(img => {
    function didLoad(){
        loaded++;
        if(loaded == images.flat().length){
            preloadObject.complete = true;
            preloadObject.onload();
        }
    }

    if(img.complete){
        didLoad();
    }else{
        img.onload = didLoad
    }
});



export default function(){
    const store = useContext(Context);

    useEffect(() => {
        store.actions.data.requestSales();
    }, [])

    if(!store.state.data.sales) return null;

    return (
        <>
            <StatefulComponent salesData={store.state.data.sales} images={getImages()} />
            <Loader item={preloadObject} />
        </>
    )
}


function StatefulComponent({salesData, images}:{salesData:SalesData, images:Array<Array<HTMLImageElement>>}){
    const [state, setState] = useState({
        selectionType: optionsType[0].value,
        selectionPrice: optionsPriceRange[0].value,
        selectedApartment: null as null|string,
        selectionLevel: 16,
        targetFrame:0,
        selectedPlan: null as null|string,
        showView:false,
        currentView:0,
    })

    const store = useContext(Context);

    // console.log("Apartment: " + state.selectedApartment)


    useEffect(() => {
        if(!!store.state.render.src){
            setState({
                ...state,
                showView: true,
            })
        }
    }, [store.state.render.src]);


    useEffect(() => {
        if(!state.showView){
            store.actions.render.hideRender();
        }
    }, [state.showView])

    
    useEffect(() => {
        if(!state.showView){
            store.actions.plan.hideApartmentPlan();
        }
    }, [state.showView])

    function onSelectionType(selectionType:number){
        setState({
            ...state,
            selectionType
        })
    }

    function onSelectionPrice(selectionPrice:string){
        setState({
            ...state,
            selectionPrice
        })
    }

    function onSelectionLevel(selectionLevel:number){
        setState({
            ...state,
            selectionLevel
        })
    }

    function onSelectionApartment(apartmentId:string){
        store.actions.plan.showApartmentPlan(apartmentId);
    }

    function onNext(){
        setState({
            ...state,
            targetFrame: state.targetFrame + 18,
        })
    }

    function onPrev(){
        setState({
            ...state,
            targetFrame: state.targetFrame - 18,
        })
    }

    //const coordData = CoordData.at(state.selectionLevel, modWrap(state.targetFrame, NUM_FRAMES+1));
    //console.log(coordData);

    const filteredSalesData:SalesData = {};
    Object.keys(salesData).forEach(key => {
        var value:SalesData[number] = salesData[key];
        var price = value.price;
        var minMax = getMinMax(state.selectionPrice);
        var min = minMax[0] * 1000;
        var max = minMax[1] * 1000;
        var passesPrice = price >= min && price < max;
        var passesBedrooms = state.selectionType==-1 || state.selectionType==value.bed;
        var passes = passesPrice && passesBedrooms;
        if(passes){
            filteredSalesData[key] = value;
        }
    })

    /*
    const filteredCoordData:Record<number, {title:string, x:number, y:number}[][]> = {};
    console.log("Coord data: ");
    console.log(coordData)
    Object.keys(coordData).forEach((key:any) => {
        filteredCoordData[key] = [];
        var datas = coordData[key];
        datas.forEach((data, i) => {
            filteredCoordData[key][i] = [];
            var coords = coordData[key][i];
            coords.forEach((coord, j) => {
                //var num = parseInt(coord.title)
                //if(filteredSalesData)
                var title = coord.title[0]=="0" ? coord.title.slice(1) : coord.title;
                if(filteredSalesData[title]) filteredCoordData[key][i].push(coord);
            })
        })
    })
    */

    // console.log("Coords filtered");
    // console.log('coordData', coordData)
    // console.log('salesData', salesData)
    // console.log('filteredSalesData', filteredSalesData)

    function getNumApartmentsOnLevel(level:number){
        var result = 0;
        for(var i = 0; i < 99; i++){
            var r = i<10 ? "0"+i : i; 
            var apartmentData = filteredSalesData["" + level + r];
            if(apartmentData && apartmentData.status==="Available"){
                result++;
            }
        }
        return result;
    }

    const updatedOptions = optionsLevels.map(option => ({
        ...option,
        num:getNumApartmentsOnLevel(option.level),
    }))


    function onFavouriteClicked(){
        store.actions.favourites.show();
    }

    
    function showView(){
        setState({
            ...state,
            showView: true,
            currentView: 0,
        })
    }

    function onLightboxSelect(item:string|null){
        if(item==null){
            setState({
                ...state,
                showView: false,
                currentView: 0,
            })
            return
        }

        const idx = items.findIndex((value) => value.src === item)

        setState({
            ...state,
            currentView: (idx === -1) ? 0 : idx,
        })

        // let idx = -1
        // if(item==null){
        //     idx = items.findIndex((value) => {value.src === item})
        // }

        // if(idx === -1){
        //     
        // }
    }

    var apartmentIdFourDigit = (store.state.plan.apartmentId && store.state.plan.apartmentId.length==3) ? ("0"+store.state.plan.apartmentId) : store.state.plan.apartmentId;
    const items = state.showView ? [
        store.state.render.src
        ? { src:`${process.env.PUBLIC_URL}/renders/${store.state.render.src}.jpg`, caption:store.state.render.caption! }
        : { src:`${process.env.PUBLIC_URL}/views/${apartmentIdFourDigit}.jpg`, caption:"View from Apartment "+store.state.plan.apartmentId }
    ] : []

    // Fix for 1101, 1201, 1301 having been merged with 1108, 1208, 1308 respectively
    if ( state.showView && !store.state.render.src && apartmentIdFourDigit && ["1101", "1201", "1301"].indexOf(apartmentIdFourDigit) > -1 )
    {
        const mergedApartmentId = apartmentIdFourDigit.slice(0, -2) + '08'
        items.push(
            { src:`${process.env.PUBLIC_URL}/views/${mergedApartmentId}.jpg`, caption:"View from Apartment "+store.state.plan.apartmentId }
        )
    }

    function toggleLights(){
        if(store.state.lighting.selection=='all'){
            store.actions.lighting.set(null)
        }else{
            store.actions.lighting.set('all')
        }
    }

    return (
        <>  
            <Floorplan onSelectionApartment={onSelectionApartment} salesData={filteredSalesData} images={images} coords={coordData} numLevels={NUM_FRAMES} numFrames={NUM_FRAMES} targetLevel={state.selectionLevel-1} targetFrame={state.targetFrame} onNext={onNext} onPrev={onPrev}/>
            <Plan onShowView={showView} />
            <SidePanel width={450} justifyContent="flex-start" paddingSide={44} paddingTop={42}>
                <div className="NavFloorPlans">
                    <Selection label="TYPE" options={optionsType} selected={state.selectionType} onSelection={onSelectionType} />
                    <Selection label="PRICE RANGE" options={optionsPriceRange} selected={state.selectionPrice} onSelection={onSelectionPrice} />
                    <SidePanelButton label="Favourites" onClick={onFavouriteClicked} icon={<FontAwesomeIcon icon={ store.state.favourites.all.length > 0 ? faHeartSolid : faHeartHollow}/>}/>
                    <SidePanelButton label={"Lights On"} onClick={toggleLights} icon={<FontAwesomeIcon icon={ store.state.lighting.selection=='all' ? faLightbulbSolid : faLightbulbHollow}/>}/>
                    <LevelSelector selected={state.selectionLevel} options={updatedOptions} onSelection={onSelectionLevel} />
                </div>
            </SidePanel>
            <Lightbox items={items} selection={state.showView ? items[state.currentView].src : null} onSelected={onLightboxSelect} carousel={items.length>1} />
        </>
    )
}
