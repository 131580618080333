export type State = { fields: typeof initialState };

const initialState = {
    count: 1,
    id: 'aaa'
}


const inc = () => (state:State) => {
    // console.log("Incrememet");
    state.fields.count++
}


const setId = (id:string) => (state:State) => {
    state.fields.id = id;
}


export default {
    initialState,
    actions:{
        inc,
        setId,
    }
}
