import React from "react";


type Props = {
    top?: string,
    onSubmit?: Function,
    children: React.ReactNode,
}


export default function({children, top, onSubmit}:Props){
    const style = {
        position: 'relative',
        top,
    } as React.CSSProperties;

    const submitFunction:React.FormEventHandler = (event:React.FormEvent) => {
        event.preventDefault();
        if (typeof onSubmit === "function")
        {
            onSubmit();
        }
    }

    return (
        <div className='Form' style={style}>
            <form onSubmit={ submitFunction }>
                { children }
                <input type="submit" style={{display: 'none'}} /> 
            </form>
        </div>
    )
}
