import { apiFetch } from "./helper/api";


var host = '192.168.1.100:3000';

(window as any).setHost = function(newHost:string){
    host = newHost;
}


async function turnOnApt(id:string){
    try{
        id = fourDigitId(id);
        const response = await apiFetch(`http://${host}/apt?number=${id}`, {});
        return await response.text();
    }catch(e){
        return null;
    }
}


async function turnOnGroup(id:string){
    try{
        id = fourDigitId(id);
        const response = await apiFetch(`http://${host}/group?number=${id}`, {});
        return await response.text();
    }catch(e){
        return null;
    }
}


async function allOn(){
    try{
        const response = await apiFetch(`http://${host}/allOn`, {});
        return await response.text();
    }catch(e){
        return null;
    }
}


async function allOff(){
    try{
        const response = await apiFetch(`http://${host}/allOff`, {});
        return await response.text();
    }catch(e){
        return null;
    }
}


function fourDigitId(id:string){
    if(id.length != 3 && id.length != 4) throw("Invalid id: " + id);
    if(id.length == 3) return '0' + id;
    return id;
}


export default{
    turnOnApt,
    turnOnGroup,
    allOn,
    allOff,
}