import { useState } from 'react';
import ImageLoader from '../components/ImageLoader'
import Lightbox from '../components/Lightbox';
import ThumbnailPicker from '../components/ThumbnailPicker'

const items = [
    { src:require("../images/design/img--exterior-corner.jpg").default, caption:"EXTERIOR CORNER", x:640, y:200, width:384, height:500 },
    { src:require("../images/design/img--hero.jpg").default, caption:"AERIAL EXTERIOR", x:1036, y:200, width:384, height:500 },
    { src:require("../images/design/img--rooftop-amenity.jpg").default, caption:"ROOFTOP AMENITY", x:1434, y:200, width:386, height:254 },
    { src:require("../images/design/img--fireplace-lounge.jpg").default, caption:"FIREPLACE LOUNGE", x:1434, y:464, width:386, height:236 },
    { src:require("../images/design/img--balconies.jpg").default, caption:"BALCONIES", x:640, y:714, width:384, height:268 },
    { src:require("../images/design/img--bar.jpg").default, caption:"BAR", x:1036, y:714, width:384, height:268 },
    { src:require("../images/design/img--pool.jpg").default, caption:"POOL", x:1434, y:714, width:386, height:268 },
]


export default function(){
    const [selection, setSelection] = useState<string|null>(null);

    return (
        <div>
            <ThumbnailPicker onSelected={setSelection} items={items} debug={false}/>
            <Lightbox items={items} selection={selection} onSelected={setSelection} carousel={true}/>
            <ImageLoader src={require("../images/design/bkg--design-2.jpg").default} />
        </div>
    )
}