type CoordData = {
    title:string,
    x:number,
    y:number,
    level:number,
    caption:string|null,
}


function parse(xmlString:string, level:number):Record<string, CoordData>{
    const parser = new DOMParser();
    const xml = parser.parseFromString(xmlString, 'text/xml');    


    var result:Record<string, CoordData> = {};
    var items = xml.getElementsByTagName("item");
    for(var i=0; i < items.length; i++){
        var item = items[i]
        var title = item.getAttribute('title')!
        result[title] = {
            title,
            x: parseFloat(item.getAttribute('xPos')!),
            y: parseFloat(item.getAttribute('yPos')!),
            level: level,
            caption: item.getAttribute("caption"),
        } as CoordData;
    }

    // console.log(result);

    return result;
}



export default {
    18: {
        ...parse(require('!!raw-loader!./coords/00/level1_00.xml').default, 0),
        ...parse(require('!!raw-loader!./coords/00/level2_00.xml').default, 1),
        ...parse(require('!!raw-loader!./coords/00/level3_00.xml').default, 2),
        ...parse(require('!!raw-loader!./coords/00/level4_00.xml').default, 3),
        ...parse(require('!!raw-loader!./coords/00/level5_00.xml').default, 4),
        ...parse(require('!!raw-loader!./coords/00/level6_00.xml').default, 5),
        ...parse(require('!!raw-loader!./coords/00/level7_00.xml').default, 6),
        ...parse(require('!!raw-loader!./coords/00/level8_00.xml').default, 7),
        ...parse(require('!!raw-loader!./coords/00/level9_00.xml').default, 8),
        ...parse(require('!!raw-loader!./coords/00/level10_00.xml').default, 9),
        ...parse(require('!!raw-loader!./coords/00/level11_00.xml').default, 10),
        ...parse(require('!!raw-loader!./coords/00/level12_00.xml').default, 11),
        ...parse(require('!!raw-loader!./coords/00/level13_00.xml').default, 12),
        ...parse(require('!!raw-loader!./coords/00/level14_00.xml').default, 13),
        ...parse(require('!!raw-loader!./coords/00/level15_00.xml').default, 14),
        ...parse(require('!!raw-loader!./coords/00/level16_00.xml').default, 15),
    },
    0: {
        ...parse(require('!!raw-loader!./coords/18/level1_18.xml').default, 0),
        ...parse(require('!!raw-loader!./coords/18/level2_18.xml').default, 1),
        ...parse(require('!!raw-loader!./coords/18/level3_18.xml').default, 2),
        ...parse(require('!!raw-loader!./coords/18/level4_18.xml').default, 3),
        ...parse(require('!!raw-loader!./coords/18/level5_18.xml').default, 4),
        ...parse(require('!!raw-loader!./coords/18/level6_18.xml').default, 5),
        ...parse(require('!!raw-loader!./coords/18/level7_18.xml').default, 6),
        ...parse(require('!!raw-loader!./coords/18/level8_18.xml').default, 7),
        ...parse(require('!!raw-loader!./coords/18/level9_18.xml').default, 8),
        ...parse(require('!!raw-loader!./coords/18/level10_18.xml').default, 9),
        ...parse(require('!!raw-loader!./coords/18/level11_18.xml').default, 10),
        ...parse(require('!!raw-loader!./coords/18/level12_18.xml').default, 11),
        ...parse(require('!!raw-loader!./coords/18/level13_18.xml').default, 12),
        ...parse(require('!!raw-loader!./coords/18/level14_18.xml').default, 13),
        ...parse(require('!!raw-loader!./coords/18/level15_18.xml').default, 14),
        ...parse(require('!!raw-loader!./coords/18/level16_18.xml').default, 15),
    }
} as Record<number, Record<string, CoordData>>

