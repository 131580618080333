function getPlan(apartmentId:string){
    apartmentId = "" + apartmentId;
    if(apartmentId.length==3) apartmentId = "" + 0 + apartmentId;
    return jpgs.find(jpg => jpg.indexOf(apartmentId) != -1)!;
}


function getPDF(planId:string|undefined){
    return planId + '.pdf'
}


function hasPlan(apartmentId:string){
    return getPlan(apartmentId) != null;
}

export default {
    getPlan,
    getPDF,
    hasPlan,
}


const jpgs = [
    'Type_A_0210_0310_0410_0510',
    'Type_A1_0107',
    'Type_B_0211_0311_0411_0511',
    'Type_B1_0108',
    'Type_C_0201_0301_0401_0501',
    'Type_C1_0101',
    'Type_D_0202_0302_0402_0502',
    'Type_D1_0102',
    'Type_E_0203_0303_0403_0503',
    'Type_E1_0103',
    'Type_F_0204_0304_0404_0504',
    'Type_F1_0104',
    'Type_G_0205_0305_0405_0505',
    'Type_G1_0105',
    'Type_H_0206_0306_0406_0506',
    'Type_H1_0106',
    'Type_I_J_0207_0208_0307_0308',
    'Type_K_0209_0309_0409_0509_0607_0707_0807_0907_1007_1107_1207_1307',
    'Type_L_0407_0507_0605_0705_0805_0905_1005_1105_1205_1305',
    'Type_M_0408_0508_0606_0706_0806_0906_1006_1106_1206_1306',
    'Type_N_0608_0708_0808_0908_1008_1108_1208_1308',
    'Type_O_0601_0701_0801_0901_1001_1101',
    'Type_P_0602_0702_0802_0902_1002_1102_1202_1302',
    'Type_Q_0603_0703_0803_0903_1003_1103_1203_1303',
    'Type_R_0604_0704_0804_0904_1004_1104_1204_1304',
    'Type_S_1401',
    'Type_SP_1201',
    'Type_SP_1301',
    'Type_T1_1402',
    'Type_T2_1403',
    'Type_U_1501',
    'Type_V_1502',
    'Type_W_1503',
    'Type_Z_1404',
]