import { faCheckSquare as checked } from '@fortawesome/free-solid-svg-icons'
import { faSquare as unchecked } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


type Props<N extends string> = {
    name:N,
    value:boolean,
    label:React.ReactNode,
    onChange: (name:N, value:boolean) => void,
}


export default function<N extends string>({name, value, label, onChange}:Props<N>){
    function toggle(){
        onChange(name, !value);
    }

    return (
        <div className="Check" id={`field-${name}`} onClick={toggle}>
            <div className="text">{label}</div>
            <div className="check-icon">
                <FontAwesomeIcon size="lg" icon={value?checked:unchecked}/>
            </div>
        </div>
    )
}
