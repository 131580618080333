import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import reportWebVitals from './reportWebVitals';


import './style/index.scss'

const isLocal = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

if(!isLocal && window.location.protocol == "http:"){
	var redirectPath = window.location.href.replace("http:", "https:");
	window.location.href = redirectPath;
}else{
	ReactDOM.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
		document.getElementById('root')
	);

	// If you want to start measuring performance in your app, pass a function
	// to log results (for example: reportWebVitals(console.log))
	// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
	reportWebVitals();
}